<script setup>
</script>

<template>
    <div>
        <img src="/images/header_titel.jpg" class="h-auto max-w-full" alt="header" />
    </div>
</template>

<style lang="postcss" scoped></style>
