<script setup>
const { t: $t } = useI18n();

const hostEmailAddress = ref('mailto:' + $t('host.emailAddressPrefix') + '@' + $t('host.emailAddressDomain'));

</script>

<template>
    <div class="footer">
        <div class="footercontacts">
            <div class="footercontacts__wrapper">
                <div class="footercontacts__social">
                    <a href="https://www.linkedin.com/company/huawei-fusionsolar-deutschland" target="“_blank“" rel="noopener
                    noreferrer"><img src="/images/icons/linkedin-icon.png" alt="LinkedIn" width="50" height="50"></a>
                    <a href="https://www.youtube.com/@HuaweiFusionSolarDeutschland" target="“_blank“" rel="noopener
                    noreferrer"><img src="/images/icons/youtube-icon.png" alt="YouTube" width="50" height="50"></a>
                </div>
                <p><strong>{{ $t('footer.technicalSupport') }}</strong><br>
                    <a :href="hostEmailAddress">{{ $t('host.emailAddressPrefix') }}@{{ $t('host.emailAddressDomain')
                        }}</a><br>
                    <a href="tel:+4923317362230">02331 7362230</a>
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
a {
    color: #333;
    text-decoration: none;
}

.footer {
    font-size: .8888888889rem;
    line-height: 1.5;
    background-color: #dcdddd;
    border-top: 1px solid #dcdddd;
    margin-top: 3rem;
}

.footercontacts {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.footercontacts__wrapper {
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
}

@media (min-width: 50em) and (max-width: 75em) {
    .footercontacts__wrapper {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.footercontacts__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: .75em;
}
</style>