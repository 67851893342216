<script setup>
const { locale, locales, setLocale, setLocaleCookie } = useI18n();
const localePath = useLocalePath(); // Wird in Link gebraucht
const switchLocalePath = useSwitchLocalePath();

const selectedLocaleName = ref('');
const selectedLocale = ref(locales.value.find((item) => item.code === locale.value));
selectedLocaleName.value = selectedLocale.value.name;

const visible = ref(false);

const availableLocales = computed(() => {
    return locales.value.filter(i => i.code !== locale.value);
})

watch(locale, (newLocale) => {
    // Die json-lang-Dateien werden nicht geladen?
    if (newLocale) {
        //console.log(newLocale);

        const selectedLocaleW = ref(locales.value.find((item) => item.code === newLocale));
        selectedLocaleName.value = selectedLocaleW.value.name;
        //selectedLocaleName.value = selectedLocale.value.name;
        //setLocaleCookie(newLocale);
        //setLocale(newLocale);
    }
});
</script>

<template>
    <!-- <h1>1. {{ locale }}</h1>
    <h1>2. {{ selectedLocaleName }}</h1> -->
    <!-- <h1>3. {{ selectedLocale }}</h1> -->
    <div class="">
        <Button :label="selectedLocaleName" text size="small" @click="visible = true" />
        <!-- Alle locale flaggen nebeneinander -->
        <!-- <NuxtLink v-for="locale in availableLocales" :key="locale.code" :to="switchLocalePath(locale.code)">
            <img :src="`/images/icons/flags/flag-${locale.code}.jpg`" height="20" />
            <span class="flag" v-html="locale.flag"></span>
        </NuxtLink> -->
        <!-- Alle locale button nebeneinander -->
        <!-- <div>
            <button @click="setLocale('de')">de</button>
            <button @click="setLocale('en')">en</button>
            <button @click="setLocale('es')">es</button>
            <button @click="setLocale('fr')">fr</button>
        </div> -->
    </div>

    <Dialog v-model:visible="visible" modal :style="{ width: '20rem' }">
        <div class="grid grid-cols-3 gap-4">
            <NuxtLink v-for="locale in availableLocales" :key="locale.code" :to="switchLocalePath(locale.code)"
                @click="visible = false" title="language">
                <img :src="`/images/icons/flags/flag-${locale.code}.jpg`" width="48" />
                <div>{{ locale.languageName }}</div>
            </NuxtLink>
        </div>
    </Dialog>


    <!-- DAS HIER FUNKTIONIERT NICHT. Die  lang.JSON Datei wird nach Wechsel nicht geladen? -->
    <!-- <select v-model="locale">
        <option v-for="locale in locales" :key="locale" :value="locale.code" v-html="locale.name" />
    </select> -->
</template>

<style></style>
