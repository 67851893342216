<script setup>
const sessionData = useSessionData();
const { locale, locales, defaultLocale, t: $t } = useI18n();
const route = useRoute();
const localePath = useLocalePath(); // Wird in Links gebraucht
</script>

<template>
    <div class="flex-container">
        <div>
            <NuxtLink :to="localePath({ name: 'index' })" title="index">{{ $t('link.index') }}</NuxtLink>
        </div>
        <div>
            <NuxtLink :to="localePath({ name: 'privacy' })" title="privacy">{{ $t('link.privacy') }}</NuxtLink>
        </div>
        <div>
            <NuxtLink :to="localePath({ name: 'imprint' })" title="imprint">{{ $t('link.imprint') }}</NuxtLink>
        </div>
    </div>
</template>

<style lang="scss" scoped>
a {
    color: #fff;
    text-decoration: none;
}

.flex-container {
    display: flex;
}

.flex-container>div {
    margin: 10px;
    text-align: center;
    line-height: 32px;
}
</style>