<script lang="ts" setup>
const { enabledLanguageSwitch } = useRuntimeConfig().public;
const isEnabledLanguageSwitch = (enabledLanguageSwitch === 'true') ? true : false;
</script>

<template>
    <!-- <div v-if="isEnabledLanguageSwitch">
        <ClientOnly>
            <div class="mx-2 lg:mx-0">
                <LanguageSwitch />
            </div>
        </ClientOnly>
    </div>
    <HeaderImage /> -->
    <header>
        <!-- Navigation bar -->
        <nav class="relative h-[48px] flex w-full bg-white lg:flex-wrap lg:justify-end">
            <div class="flex flex-row-reverse my-2">
                <div v-if="isEnabledLanguageSwitch">
                    <ClientOnly>
                        <div class="mx-2 lg:mx-0">
                            <LanguageSwitch />
                        </div>
                    </ClientOnly>
                </div>
            </div>
        </nav>
        <HeaderImage />
    </header>
</template>

<style></style>
