<script setup>
import AppHeader from '~/components/AppHeader.vue';

const route = useRoute();
const { t } = useI18n();
const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
});

const title = computed(() =>
    t(route.meta.title ?? 'meta.title.default', t('meta.title.default'))
);

</script>

<template>
    <div>
        <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">

        </Html>

        <Head>
            <Title>{{ title }}</Title>
            <template v-for="link in head.link" :key="link.id">
                <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
            </template>
            <template v-for="meta in head.meta" :key="meta.id">
                <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
            </template>
        </Head>

        <Body>
            <Toast position="top-center" />
            <div class="container">
                <AppHeader />
                <AppSpacer />
                <div>
                    <slot />
                </div>
            </div>
            <div class="footer">
                <div class="container">
                    <AppFooter />
                </div>
            </div>
            <div class="menu">
                <div class="container">
                    <AppMenu />
                </div>
            </div>
        </Body>
    </div>
</template>

<style lang="scss" scoped>
.container {
    max-width: 992px;
    margin: 0 auto;
}

.footer {
    background-color: #dcdddd;
}

.menu {
    background-color: #333;
}
</style>
